import React from 'react';
import styled from 'styled-components';
import {Modal, message, Alert} from 'antd';
import qs from 'query-string';
import {getNewOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom.js';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss.js';
import Config from '../../data.json';

getNewOutlet('AdminReassignOrderModal', null, {autoDelete: false});

export default function AdminReassignOrderModal(props) {
  const [modalData, setModalData] = useOutlet('AdminReassignOrderModal');
  const [user] = useOutlet('user');

  return (
    <Modal
      title="線下付款"
      footer={null}
      style={{top: 20}}
      bodyStyle={{padding: 0}}
      width={650}
      visible={modalData}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      <ModalContent
        modalData={modalData}
        setModalData={setModalData}
        user={user}
      />
    </Modal>
  );
}

function ModalContent(props) {
  const {modalData, setModalData} = props;
  const {instance} = modalData;

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <Alert
        showIcon
        type="warning"
        message={
          <div>
            <p>請注意：進行本操作前，請先確認已向會員本人收到款項。</p>
            <p>
              請注意：金額可能依據會員狀態、次狀態，以及是否使用進修時數、免費時數而有不同。
            </p>
            <p>請注意：確認完成後，系統會修改付款狀態為「成功」。</p>
          </div>
        }
        style={{marginBottom: 10}}
      />

      <div className="container">
        <GenericForm
          rjsfProps={rjsfProps}
          instance={instance}
          schema={{
            title: '',
            type: 'object',
            required: ['owner', 'offline_date', 'total', 'staff_note'],
            properties: {
              owner: {type: 'string', title: '指定會員'},
              payment_offline_method: {
                type: 'string',
                enum: ['', '郵政劃撥', '傳真刷卡', '現金', 'ATM轉帳匯款'],
                title: '線下方式',
              },
              offline_date: {
                type: 'string',
                title: '線下收款日期',
                format: 'date',
              },
              total: {
                type: 'integer',
                title: '總金額',
              },
              staff_note: {
                type: 'string',
                title: '備註',
              },
            },
          }}
          uiSchema={{
            owner: {
              'ui:widget': 'admin-select-user-custom-widget',
              'ui:options': {label: false},
            },
          }}
          onSubmit={async (formData, extValue) => {
            AppActions.setLoading(true);

            try {
              if (formData.owner) {
                const upr = await JStorage.fetchOneDocument('user_profile', {
                  owner: formData.owner,
                });
                const ppr = await JStorage.fetchOneDocument('private_profile', {
                  owner: formData.owner,
                });

                const data = {
                  payment_offline_method: formData.payment_offline_method || '',
                  offline_date: formData.offline_date || '',
                  //buyer
                  buyer_name: upr.name,
                  buyer_phone: upr.phone,
                  buyer_email: upr.email,
                  buyer_zip: upr.mail_address_zip_code || '',
                  buyer_city: upr.mail_address_city?.split('-')[0] || '',
                  buyer_district: upr.mail_address_city?.split('-')[1] || '',
                  buyer_address: upr.mail_address_detail || '',
                  //receiver
                  receiver_name: upr.name,
                  receiver_phone: upr.phone,
                  receiver_email: upr.email,
                  receiver_zip: upr.mail_address_zip_code || '',
                  receiver_city: upr.mail_address_city?.split('-')[0] || '',
                  receiver_district: upr.mail_address_city?.split('-')[1] || '',
                  receiver_address: upr.mail_address_detail || '',
                  owner_type: ppr.user_type,
                  staff_note: instance.staff_note
                    ? `${instance.staff_note}  ${formData.staff_note}`
                    : formData.staff_note,
                };

                if (instance.owner !== formData.owner) {
                  data.owner = formData.owner;
                }

                const order = await AppActionsEx.reassignOrder({
                  order_number: instance.order_number,
                  data,
                });

                setModalData(null);
                message.success('現場付款成功，請稍候......');

                setTimeout(() => {
                  const params = qs.parse(window.location.search) || {};
                  const path = `${Config.webHost}/admin/order/?action=detail&id=${order.id}`;
                  const defaultBackPath = `/admin/orders/export`;

                  if (params.backPath) {
                    window.location.replace(
                      `${path}&backPath=${params.backPath}`,
                    );
                  } else {
                    window.location.replace(
                      `${path}&backPath=${defaultBackPath}`,
                    );
                  }
                }, 300);
              } else {
                message.error('未設定會員');
              }
              AppActions.setLoading(false);
            } catch (err) {
              console.warn(err);
              message.error('現場付款失敗');
              AppActions.setLoading(false);
            }
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .container {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
